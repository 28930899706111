<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <user-view-user-info-card :user-data="userData" />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
        <statistic-card-with-area-chart
          v-if="userData.performance"
          icon="InfoIcon"
          color="warning"
          statistic-title="Performance For 4 Last Attempted Lessons"
          :statistic="kFormatter(getTotalLessonPerformance(userData.performance))"
          :chart-data="getLessonsPerformance(userData.performance)"
        />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-timeline-card :activity="userData.activity" />
        </b-col>
      </b-row>

      <user-exercises-responses :responses="userData.exercise_responses" />

      <b-row>
        <b-col cols="6">
          <user-chat v-if="!$can('content', 'view')" :user="userData" :messages="userMessages" />
        </b-col>
        <b-col cols="6" v-if="userData.articles.length">
          <user-articles :articles="userData.articles" />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted, onBeforeMount } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import userStoreModule from '../userStoreModule'
import { kFormatter } from '@core/utils/filter'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import UserArticles from './UserArticles.vue'
import UserChat from './UserChat.vue'
import UserExercisesResponses from './UserExercisesResponses.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserTimelineCard,
    StatisticCardWithAreaChart,
    UserArticles,
    UserChat,
    UserExercisesResponses,
  },
  setup() {
    const userData = ref(null)
    const userMessages = ref({})
    const userMessageContacts = ref(null)
    const profileUser = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const printMessage = (message) => {
      console.log(message)
    }

    const getLessonsPerformance = (performances) => {
      const performance = {
        lessons: {
          series: [
            {
              name: 'Lesson Performance',
              data: []
            }
          ]
        }
      }
      performances.forEach(student_performance => {
        performance.lessons.series[0].data.push(student_performance.student_performance)
      })
      return performance.lessons.series
    }

    const getTotalLessonPerformance = (performances) => {
      let totalPerformance = 0
      performances.forEach(student_performance => {
        totalPerformance += student_performance.student_performance
      })
      return totalPerformance / 4
    }

    store.dispatch('app-user/getProfileUser')
      .then(response => {
        profileUser.value = response.data
      })

    store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
          userData.value = response.data.user
          userMessages.value.messages = response.data.conversation
          userMessages.value.user = userData.value
          // console.log(userMessages.value.messages)
        })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })


    return {
      kFormatter,
      userData,
      userMessages,
      userMessageContacts,
      getLessonsPerformance,
      getTotalLessonPerformance,
      printMessage,
    }
  },
}
</script>

<style>

</style>
