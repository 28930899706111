import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("admin/users", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchTeachers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("admin/teachers", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/user/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchTeacher(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/teacher/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addTeacher(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/teachers/add", userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addTeacherToSchool(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/teacher/school/add', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllSchools(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/schools/all')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTeacherConversation(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/teacher/conversation/'+id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        axios
          .get("/admin/profile")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
