<template>
  <b-card
    title="Exercises Responses"
  >
    <!-- table -->
    <b-table
      :sticky-header="stickyHeader"
      responsive
      :items="responses"
      :fields="fields"
      striped
      class="mb-0"
    >
      <template #cell(chapter)="row">
        {{ row.item.exerciseable.exercise.lesson.section.chapter.name }}
      </template>

      <template #cell(section)="row">
        {{ row.item.exerciseable.exercise.lesson.section.name }}
      </template>

      <template #cell(lesson)="row">
        {{ row.item.exerciseable.exercise.lesson.name }}
      </template>

      <template #cell(exerciseable_type)="row">
        {{ formatType(row.item.exerciseable_type) }}
      </template>

      <template #cell(updated_at)="row">
        {{ moment(row.item.updated_at).format("MMM DD, YYYY") }}
      </template>

      <template #cell(show_details)="row">

        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
        <b-form-checkbox
          v-model="row.detailsShowing"
          @change="row.toggleDetails"
        >
          {{ row.detailsShowing ? 'Hide' : 'Show' }}
        </b-form-checkbox>
      </template>

      <!-- full detail on click -->
      <template #row-details="row">
        <b-card>
          <div v-if="row.item.exerciseable_type === 'App\\Models\\GroupExercise'">
            <b-row>
              <b-col :cols="row.item.exerciseable.translation ? '6' : '12'">
                <label for="">Exercise Instruction</label>
                <b-card>
                  {{ row.item.exerciseable.instruction }}
                </b-card>
                <label for="">Exercise Activity</label>
                <b-card v-html="formatHtml(row.item.exerciseable.activity)">
                </b-card>
                <div class="demo-inline-spacing">
                  <span>
                    Student response required?
                  </span>
                  <span v-if="row.item.exerciseable.requires_response">Yes</span>
                  <span v-else>No</span>
                </div>
                <br>
                <label v-if="row.item.exerciseable.requires_response === true && row.item.exerciseable.expected_response != null">Expected Student Response</label>
                <b-card v-if="row.item.exerciseable.requires_response === true && row.item.exerciseable.expected_response != null" v-html="formatHtml(row.exerciseable.expected_response)">
                </b-card>
              </b-col>
              <b-col cols="6" v-if="row.item.exerciseable.translation">
                <label for="">Exercise Instruction Translation</label>
                <b-card>
                  {{ row.item.exerciseable.translation.instruction }}
                </b-card>
                <label for="">Exercise Activity Translation</label>
                <b-card v-html="formatHtml(row.item.exerciseable.translation.activity)">
                </b-card>
                <br>
                <label v-if="row.item.exerciseable.requires_response === true && row.item.exerciseable.translation.expected_response != null">Expected Student Response Translation</label>
                <b-card v-if="row.item.exerciseable.requires_response === true && row.item.exerciseable.translation.expected_response != null" v-html="formatHtml(row.item.exerciseable.translation.expected_response)">
                </b-card>
              </b-col>
            </b-row>
          </div>

          <div v-if="row.item.exerciseable_type == 'App\\Models\\Information'">
            <b-row>
              <b-col :cols="row.item.exerciseable.translation ? '6' : '12'">
                <label for="">Topic</label>
                <b-card>
                  {{ row.item.exerciseable.topic }}
                </b-card>
                <label for="" v-if="row.item.exerciseable.subtopic != ''">Subtopic</label>
                <b-card v-if="row.item.exerciseable.subtopic != ''">
                  {{ row.item.exerciseable.subtopic }}
                </b-card>
                <b-card v-html="formatHtml(row.item.exerciseable.content)">
                </b-card>
              </b-col>
              <b-col cols="6" v-if="row.item.exerciseable.translation">
                <label for="">Topic Translation</label>
                <b-card>
                  {{ row.item.exerciseable.translation.topic }}
                </b-card>
                <label for="" v-if="row.item.exerciseable.translation.subtopic != ''">Subtopic Translation</label>
                <b-card v-if="row.item.exerciseable.translation.subtopic != ''">
                  {{ row.item.exerciseable.translation.subtopic }}
                </b-card>
                <b-card v-html="formatHtml(row.item.exerciseable.translation.content)">
                </b-card>
              </b-col>
            </b-row>
          </div>

          <div v-if="row.item.exerciseable_type == 'App\\Models\\Conversation'">
            <b-row>
              <b-col :cols="row.item.exerciseable.translation ? '6' : '12'">
                <label for="conversation">Conversation</label>
                <b-card v-html="formatHtml(row.item.exerciseable.conversation)">
                </b-card>
              </b-col>
              <b-col cols="6" v-if="row.item.exerciseable.translation">
                <label for="conversation">Conversation Translation</label>
                <b-card v-html="formatHtml(row.item.exerciseable.translation.conversation)">
                </b-card>
              </b-col>
            </b-row>
          </div>

          <div v-if="row.item.exerciseable_type == 'App\\Models\\MatchWord'">
            <b-row>
              <b-col :cols="row.item.exerciseable.translation ? '6' : '12'">
                <b-card
                  title="Instruction"
                >
                  <b-card-text>{{ row.item.exerciseable.instruction }}.</b-card-text>
                </b-card>
                <b-card title="Words">
                  <b-badge
                    v-for="word in row.item.exerciseable.words" :key="word"
                    pill
                    variant="light-secondary"
                    class="text-capitalize"
                  >
                    {{ word }}
                  </b-badge>
                </b-card>
                <b-card title="Options">
                  <b-badge
                    v-for="option in row.item.exerciseable.options" :key="option"
                    pill
                    variant="light-warning"
                    class="text-capitalize"
                  >
                    {{ option }}
                  </b-badge>
                </b-card>
                <b-card title="Answer">
                  <b-badge
                    v-for="answer in row.item.exerciseable.answer" :key="answer"
                    pill
                    variant="light-warning"
                    class="text-capitalize"
                  >
                    {{ answer }}
                  </b-badge>
                </b-card>
              </b-col>
              <b-col cols="6" v-if="row.item.exerciseable.translation">
                <b-card
                  title="Instruction Translation"
                >
                  <b-card-text>{{ row.item.exerciseable.translation.instruction }}.</b-card-text>
                </b-card>
                <b-card title="Words">
                  <b-badge
                    v-for="word in row.item.exerciseable.translation.words" :key="word"
                    pill
                    variant="light-secondary"
                    class="text-capitalize"
                  >
                    {{ word }}
                  </b-badge>
                </b-card>
                <b-card title="Options Translation">
                  <b-badge
                    v-for="option in row.item.exerciseable.translation.options" :key="option"
                    pill
                    variant="light-warning"
                    class="text-capitalize"
                  >
                    {{ option }}
                  </b-badge>
                </b-card>
                <b-card title="Answer(s) Translation">
                  <b-badge
                    v-for="answer in row.item.exerciseable.translation.answer" :key="answer"
                    pill
                    variant="light-warning"
                    class="text-capitalize"
                  >
                    {{ answer }}
                  </b-badge>
                </b-card>
              </b-col>
            </b-row>
          </div>

          <div v-if="row.item.exerciseable_type == 'App\\Models\\Question'">
            <b-row>
              <b-col :cols="row.item.exerciseable.translation ? '6' : '12'">
                <b-card
                  title="Question"
                >
                  <b-card-text>{{ row.item.exerciseable.question }}.</b-card-text>
                </b-card>
                <b-card title="Words">
                  <b-badge
                    v-for="option in row.item.exerciseable.options" :key="option"
                    pill
                    variant="light-secondary"
                    class="text-capitalize"
                  >
                    {{ option }}
                  </b-badge>
                </b-card>
                <b-card title="Answer">
                  <b-badge
                    v-for="answer in row.item.exerciseable.answer" :key="answer"
                    pill
                    variant="light-warning"
                    class="text-capitalize"
                  >
                    {{ answer }}
                  </b-badge>
                </b-card>
              </b-col>
              <b-col cols="6" v-if="row.item.exerciseable.translation">
                <b-card
                  title="Question Translation"
                >
                  <b-card-text>{{ row.item.exerciseable.translation.question }}.</b-card-text>
                </b-card>
                <b-card title="Words Translation">
                  <b-badge
                    v-for="option in row.item.exerciseable.translation.options" :key="option"
                    pill
                    variant="light-secondary"
                    class="text-capitalize"
                  >
                    {{ option }}
                  </b-badge>
                </b-card>
                <b-card title="Answer(s) Translation">
                  <b-badge
                    v-for="answer in row.item.exerciseable.translation.answer" :key="answer"
                    pill
                    variant="light-warning"
                    class="text-capitalize"
                  >
                    {{ answer }}
                  </b-badge>
                </b-card>
              </b-col>
            </b-row>
          </div>

          <div v-if="row.item.response">
            <b-card title="Student Response">
              <b-badge
                pill
                variant="light-success"
                class="text-capitalize" 
                v-for="response in row.item.response" :key="response">
                {{ response }}
              </b-badge>
            </b-card>
          </div>

          <b-button
            size="sm"
            variant="outline-secondary"
            @click="row.toggleDetails"
          >
            Hide Details
          </b-button>
        </b-card>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BFormCheckbox, BAvatar, BBadge, BCardBody, BCard, BFormRadio, BCardText, BButton, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BTable,
    BCardBody,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BCard,
    BFormRadio,
    BButton,
    BRow,
    BCol,
    BCardText,
  },
  props: {
    responses: {
      type: Array
    }
  },
  data() {
    return {
      stickyHeader: true,
      fields: [
        {
          key: 'exerciseable_type', label: 'Exercise Type'
        },
        {
          key: 'chapter', label: 'Chapter'
        },
        {
          key: 'section', label: 'Section'
        },
        {
          key: 'lesson', label: 'Lesson'
        },
        {
          key: 'performance', label: 'Score(%)'
        },
        {
          key: 'updated_at', label: 'Date'
        },
        'show_details',
      ],
    }
  },
  methods: {
    formatHtml(string) {
      return string.replace(/(?:\\r\\n|\\r|\\n )/g, '<br/>')
    },
    formatType(type) {
      return type.split('\\')[2];
    },
  }
}
</script>
