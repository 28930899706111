<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Uploaded Articles</b-card-title>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <div
        v-for="(article) in articles"
        :key="article.id"
        class="browser-states"
      >
        <b-media no-body>
          <b-avatar
            :src="article.cover_image_url"
            :text="avatarText(article.title)"
            size="40px"
            rounded
          />
          <h6 class="align-self-center my-auto ml-1">
            {{ article.title }}
          </h6>
        </b-media>
        <div class="d-flex align-items-center">
          <b-badge
            pill
            :variant="`light-${resolveArticleStatusVariant(article.status)}`"
            class="text-capitalize mr-1"
          >
            {{ article.status }}
          </b-badge>
          <b-button
            v-if="$can('read', 'article')"
            size="sm"
            variant="outline-info"
            :to="{name: 'apps-article-view', params: {id: article.id}}"
          >View</b-button>
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMedia, BBadge, BButton, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BBadge,
    BButton,
    BAvatar,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    articles: {
      type: Array,
    },
  },
  data() {
    return {
      avatarText,
    }
  },
  methods: {
    resolveArticleStatusVariant(status) {
      if (status === 'Approved') return 'success'
      if (status === 'Awaiting Review') return 'secondary'
      if (status === 'Requires Action') return 'warning'
      if (status === 'Disapproved') return 'danger'
      return 'primary'
    },
  },
}
</script>
