<template>
  <b-card title="User Timeline">
    <app-timeline>
      <app-timeline-item v-for="activity in activity" :key="activity.id"
        :variant="getVariant()"
        :title="activity.description"
        :time="moment(activity.created_at).fromNow()"
      />
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    activity: {
      type: Array
    }
  },
  methods: {
    getVariant() {
      let variants = [
        'primary', 'info', 'warning', 'danger'
      ];

      let int = Math.floor(Math.random() * 3);

      return variants[int]
    }
  },
}
</script>

<style>

</style>
