<template>
  <b-card class="chat-widget" no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <h5 class="mb-0">
          {{ chat_log.student.first_name }} {{ chat_log.student.last_name }}
        </h5>
      </div>
    </b-card-header>

    <section class="chat-app-window">
      <!-- User Chat Area -->
      <vue-perfect-scrollbar
        ref="refChatLogPS"
        :settings="perfectScrollbarSettings"
        class="user-chats scroll-area"
      >
        <div class="chats">
          <div
            v-for="(msgGrp, index) in chat_log.messages"
            :key="msgGrp.sender.email + index"
            class="chat"
            :class="{ 'chat-left': msgGrp.sender.email !== chat_log.contacts[1].email }"
          >
            <div class="chat-avatar">
              <b-avatar
                v-if="msgGrp.sender.email === chat_log.contacts[0].email"
                size="36"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
                :src="chat_log.contacts[0].avatar"
              />
              <b-avatar
                v-if="msgGrp.sender.email === chat_log.contacts[2].email"
                size="36"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
                :src="chat_log.contacts[2].avatar"
              />
              <b-avatar
                v-if="msgGrp.sender.email === chat_log.contacts[1].email"
                size="36"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
                :src="chat_log.contacts[1].avatar"
              />
            </div>
            <div class="chat-body">
              <div class="chat-content">
                <p>{{ msgGrp.body }}</p>
                <small class="float-right chat-time" v-if="msgGrp.sender.email !== chat_log.contacts[1].email"
                  >{{ msgGrp.sender.first_name }} {{ msgGrp.sender.last_name }},
                  {{ moment(msgGrp.created_at).format("ddd, h:mm A") }}</small
                >
                <small v-else>{{ moment(msgGrp.created_at).format("ddd, h:mm A") }}</small>
              </div>
            </div>
          </div>
        </div>
      </vue-perfect-scrollbar>

      <!-- Message Input -->
      <b-form class="chat-app-form" @submit.prevent="sendMessage">
        <b-input-group class="input-group-merge form-send-message mr-1">
          <b-form-input
            v-model="chatInputMessage"
            placeholder="Enter your message"
          />
        </b-input-group>
        <b-button variant="primary" type="submit"> Send </b-button>
      </b-form>
    </section>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BAvatar,
  BForm,
  BFormInput,
  BInputGroup,
  BButton,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export default {
  props: {
    messages: {
      type: Object,
    },
  },
  components: {
    BCard,
    BCardHeader,
    BAvatar,
    BForm,
    BFormInput,
    BInputGroup,
    BButton,

    // 3rd party
    VuePerfectScrollbar,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 550,
      },
      chatInputMessage: "",
      contacts_email: [],
      chat_log: {
        messages: [],
        contacts: [],
        student: {}
      }
    };
  },
  mounted() {
    this.chat_log.messages = this.messages.messages.messages.data ? this.messages.messages.messages.data : [] 
    this.chat_log.student = this.messages.user
    this.chat_log.contacts = this.messages.messages.contacts

    this.chat_log.contacts.forEach(contact => {
      this.contacts_email.push(contact.email)
    });

    this.$nextTick(() => {
      this.psToBottom();
    });

    const EchoInstance = new Echo({
      broadcaster: 'pusher',
      key: '8asewg.CUajUg',
      wsHost: 'realtime-pusher.ably.io',
      wsPort: 443,
      disableStats: true,
      encrypted: true,
    })
  
    if (this.chat_log.contacts[1]) {
      EchoInstance
        .channel(this.chat_log.contacts[1].email)
        .listen('.message.new', (data) => {
          if (this.contacts_email.includes(data.message.sender.email)) {
            this.chat_log.messages.push(data.message)
            this.$nextTick(() => {
              this.psToBottom();
            });
          }
        });
    }
  },
  methods: {
    sendMessage() {
      this.$http
        .post("/admin/teacher/conversation/message/send", {
          student_id: this.messages.user.id,
          message: this.chatInputMessage,
        })
        .then((response) => {
          this.chat_log.contacts = []
          this.chat_log.contacts = response.data.data.participants
          this.chat_log.messages.push(response.data.data.message);
          this.chatInputMessage = "";
          
          // Update scroll position
          // Scroll to bottom
          this.$nextTick(() => {
            this.psToBottom();
          });
        });

    },
    psToBottom() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
</style>